import request from "@/utils/request";

//会员积分明细查询
export function listScoreDetailAPI(params) {
    return request({
      url: '/api/system/vip/report/score/query/listScoreDetail',
      method: 'get',
      params
    })
}
  

//会员积分明细查询(合计汇总)
export function listScoreDetailSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/score/query/listScoreDetailSummary',
    method: 'get',
    params
  })
}