import request from '@/utils/request'

//plus会员方案   分页列表
export function plusCaseListAPI (params) {
  return request({
    url: '/api/system/vip/plus/case/list',
    method: 'get',
    params
  })
}
// plus会员方案  新增
export function plusCaseAddAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/save',
    method: 'post',
    data
  })
}
//plus会员方案  详情
export function plusCaseDetailAPI (caseId) {
  return request({
    url: '/api/system/vip/plus/case/getDetail?caseId=' + caseId,
    method: 'get'
  })
}
// plus会员方案   修改
export function plusCaseUpdateAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/update',
    method: 'put',
    data
  })
}

// plus会员方案   删除
export function plusCaseRemoveAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/remove',
    method: 'DELETE',
    data
  })
}
// plus会员方案  启用/停用
export function plusCaseIsStopAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/stop',
    method: 'PUT',
    data
  })
}
// plus会员方案  复制
export function plusCaseCopyAPI (caseIds) {
  return request({
    url: '/api/system/vip/plus/case/copy',
    method: 'PUT',
    data: { caseIds }
  })
}

//plus会员方案   更改状态
export function plusCaseUpdateStatusAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/audit',
    method: 'put',
    data
  })
}

//还原被删除会员方案
export function plusCaseRevertAPI (data) {
  return request({
    url: '/api/system/vip/plus/case/revert',
    method: 'post',
    data
  })
}
