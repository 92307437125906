import request from '@/utils/request'

// Ic/Id发卡列表
export function listEmitCard(query) {
  return request({
    url: '/api/system/vip/base/vipcardemit/list',
    method: 'get',
    params: query
  })
}

// 发放Ic/Id发卡
export function addEmitCard(data) {
  return request({
    url: '/api/system/vip/base/vipcardemit/save',
    method: 'post',
    data: data
  })
}

// Ic 写卡
export function writeIcCard(data) {
  return request({
    url: 'http://127.0.0.1:10880/api/local/icCard/writeCard',
    method: 'post',
    data: data
  })
}
