import request from '@/utils/request'

//更新会员规则单据状态
export function updateOrderStatus (data, url) {
  return request({
    url,
    method: 'post',
    data
  })
}
//启用停用接口
export function updateEnable (data, url) {
  return request({
    url,
    method: 'post',
    data
  })
}
//复制接口
export function copyOrder (billIds, url) {
  return request({
    url,
    method: 'post',
    data: billIds
  })
}
// 会员规则单据分页列表
export function listOrder (query, url) {
  return request({
    url,
    method: 'get',
    params: query
  })
}

//获取会员规则单据详情
export function getOrderDetail (billId, url) {
  return request({
    url: `${url}?billId=${billId}`
  })
}

//新增会员规则单据
export function addOrder (data, url) {
  return request({
    url,
    method: 'POST',
    data
  })
}

//修改会员规则单据
export function updateOrder (data, url) {
  return request({
    url,
    method: 'PUT',
    data
  })
}

//删除会员规则单据
export function delOrder (billIds, url) {
    return request({
      url,
      method: 'DELETE',
      data:billIds
    })
  }

//会员积分抵现规则列表
export function vipRebateRuleList (params) {
  return request({
    url:'/api/system/rule/list',
    params
  })
}

//会员积分抵现规则修改状态
export function vipRebateRuleUpdateStatus (data) {
  return request({
    url:'/api/system/rule/updateBillStatus',
    method:'post',
    data
  })
}

//会员积分抵现规则是否启用
export function vipRebateRuleIsStop (data) {
  return request({
    url:'/api/system/rule/isStop',
    method:'post',
    data
  })
}

//会员积分抵现规则复制
export function vipRebateRuleCopy (billIds) {
  return request({
    url:'/api/system/rule/copyVipScoreOffsetRule',
    method:'post',
    data:  billIds 
  })
}

//会员积分抵现规则删除
export function vipRebateRuleDel (billIds) {
  return request({
    url:'/api/system/rule/remove',
    method: 'DELETE',
    data:billIds
  })
}