import request from '@/utils/request'

// 会员后台换卡
export function cardReplacementList (params) {
  return request({
    url: '/api/system/vip/bill/cardChange/cardReplacementList',
    method: 'get',
    params
  })
}
