import request from '@/utils/request'

// 新增会员积分调整
export function scoreAdjustSave(data) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/save',
        method: 'post',
        data: data
    })
}
// 修改会员积分调整
export function scoreAdjustUpdate(data) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/update',
        method: 'put',
        data: data
    })
}
// 删除会员积分调整
export function scoreAdjustDel(billIds) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/remove',
        method: 'DELETE',
        data: billIds
    })
}
// 会员后台积分调整分页列表
export function scoreAdjustList(params) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/list',
        method: 'get',
        params
    })
}
// 修改会员后台积分调整单据状态
export function scoreAdjustUpdateStatus(data) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/updateBillStatus',
        method: 'post',
        data: data
    })
}
// 获取会员后台积分调整详细信息
export function scoreAdjustGetDetail(billId) {
    return request({
        url: '/api/system/vip/bill/scoreAdjust/getDetail',
        method: 'get',
        params:{
            billId
        }
    })
}
