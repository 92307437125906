import request from '@/utils/request'

// 会员卡列表
export function listVipCard (query) {
  return request({
    url: '/api/system/vip/base/vipcard/list',
    method: 'get',
    params: query
  })
}

//获取会员卡详情
export function getVipCardDetail (vipId) {
  return request({
    url: '/api/system/vip/base/vipcard/getVipInfoByVipId',
    method: 'get',
    params: { vipId }
  })
}

// 添加会员卡
export function addVipCard (data) {
  return request({
    url: '/api/system/vip/base/vipcard/save',
    method: 'post',
    data: data
  })
}

// 修改会员卡
export function updateVipCard (data) {
  return request({
    url: '/api/system/vip/base/vipcard/update',
    method: 'put',
    data: data
  })
}

// 删除会员卡
export function delVipCard (vipIds) {
  return request({
    url: '/api/system/vip/base/vipcard/remove',
    method: 'delete',
    data: vipIds
  })
}
//批量新增会员
export function batchVipCard (data) {
  return request({
    url: '/api/system/vip/base/vipcard/batchSave',
    method: 'POST',
    data
  })
}
//批量生成卡号
export function batchCardNumber (data) {
  return request({
    url: '/api/system/vip/base/vipcard/batchCreateNo',
    method: 'POST',
    data
  })
}
//批量修改
export function batchUpdate (data) {
  return request({
    url: '/api/system/vip/base/vipcard/batchUpdate',
    method: 'POST',
    data
  })
}

//会员还原
export function restoreVipCard (vipIds) {
  return request({
    url: '/api/system/vip/base/vipcard/restoreVipCard',
    method: 'POST',
    data: vipIds
  })
}

//会员导入
export function vipInfoImport (data) {
  return request({
    url: '/api/system/vip/base/vipcard/listImport',
    method: 'POST',
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  })
}

//查询会员导入结果
export function queryVipInfoImport (stateKey) {
  return request({
    url: `/api/system/vip/base/vipcard/getVipCardImportState?stateKey=${stateKey}`
  })
}


//会员导出
export function vipInfoExport (data) {
  return request({
    url: '/api/system/vip/base/vipcard/listExport',
    method: 'POST',
    data,
  })
}
//会员导出查询
export function queryVipInfoExport (key) {
  return request({
    url: `/api/system/vip/base/vipcard/getListExport?key=${key}`,
  })
}

//会员信息汇总
export function vipInfoSummary (query) {
  return request({
    url: `/api/system/vip/base/vipcard/listSummary`,
    params: query
  })
}

//会员账本
export function vipAccountBook (vipId) {
  return request({
    url: '/api/system/vip/base/vipcard/vipAccountBook?vipId=' + vipId,
    method: 'get'
  })
}

//会员充值账本明细
export function vipFillAccountBookDetail (data) {
  return request({
    url: '/api/system/vip/base/vipcard/vipFillAccountBookDetail',
    method: 'post',
    data
  })
}

//会员消费账本明细
export function vipPayAccountBookDetail (data) {
  return request({
    url: '/api/system/vip/base/vipcard/vipPayAccountBookDetail',
    method: 'post',
    data
  })
}
