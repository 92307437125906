import request from '@/utils/request'


// 会员付款金额异常
export function listPayMoneyAPI(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listPayMoney',
    method: 'get',
    params
  })
}

// 会员付款金额异常(合计汇总)
export function listPayMoneySummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listPayMoneySummary',
    method: 'get',
    params
  })
}

// 会员付款次数异常
export function listPayQtyAPI(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listPayQty',
    method: 'get',
    params
  })
}

// 会员付款次数异常(合计汇总)
export function listPayQtySummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listPayQtySummary',
    method: 'get',
    params
  })
}

// 会员积分次数异常
export function listScoreQty(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listScoreQty',
    method: 'get',
    params
  })
}

// 会员积分次数异常(合计汇总)
export function listScoreQtySummary(params) {
  return request({
    url: '/api/system/vip/report/abnormal/query/listScoreQtySummary',
    method: 'get',
    params
  })
}