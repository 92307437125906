import request from '@/utils/request'

// 微信卡包新增
export function wxVipcardAddAPI(data) {
  return request({
    url: '/api/system/vip/card/saveOrUpdate',
    method: 'post',
    data
  })
}
// 获取微信卡包详情
export function wxVipcardDetailAPI(params) {
  return request({
    url: '/api/system/vip/card/getDetail',
    method: 'get',
    params
  })
}
// 删除微信卡包
export function wxVipcardRemoveAPI() {
  return request({
    url: '/api/system/vip/card/remove',
    method: 'delete'
  })
}

// 校验微信会员卡是否合法
export function wxVipcardInspectAPI(wxCardId) {
  return request({
    url: `/api/system/vip/card/isWxCardInspect?wxCardId=${wxCardId}`,
  })
}

//微信卡包投放二维码
export function vipCardwxAddCreateQrcode(data) {
  return request({
    url: `/api/system/card/vipCardWxAddCreateQrcode`,
    method: "post",
    data
  })
}

//微信生成链接
export function vipCardWxAddCreateLink(data) {
  return request({
    url: `/api/system/card/vipCardWxAddCreateLink`,
    method: "post",
    data
  })
}

//新增会员品牌
export function vipBrandSave(data) {
  return request({
    url: `/api/system/vip/base/brand/save`,
    method: "post",
    data
  })
}

//会员品牌分页列表
export function vipBrandList(params) {
  return request({
    url: `/api/system/vip/base/brand/list`,
    method: "get",
    params
  })
}

//修改会员品牌
export function vipBrandUpdate(data) {
  return request({
    url: `/api/system/vip/base/brand/update`,
    method: "put",
    data
  })
}

//获取会员品牌详情
export function vipBrandDetail(vipBrandId) {
  return request({
    url: `/api/system/vip/base/brand/getDetail?vipBrandId=${vipBrandId}`,
    method: "get",
  })
}

//删除会员品牌
export function vipBrandRemove(data) {
  return request({
    url: `/api/system/vip/base/brand/remove`,
    method: "post",
    data
  })
}

//还原会员品牌
export function restoreVipCardBrand(data) {
  return request({
    url: `/api/system/vip/base/brand/restoreVipCardBrand`,
    method: "post",
    data
  })
}

//微信会员卡
export function getCardBrandDetail(vipBrandId) {
  return request({
    url: `/api/system/vip/card/getCardBrandDetail?vipBrandId=${vipBrandId}`,
    method: "get",
  })
}

//新增微信卡包
export function cardCaseSave(data) {
  return request({
    url: `/api/system/vip/card/save`,
    method: "post",
    data
  })
}

//修改微信卡包
export function cardCaseUpdate(data) {
  return request({
    url: `/api/system/vip/card/update`,
    method: "post",
    data
  })
}