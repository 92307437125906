import request from '@/utils/request'

// plus会员信息分页列表报表
export function plusInfoListAPI(params) {
  return request({
    url: '/api/system/vip/plus/info/list',
    method: 'get',
    params
  })
}

// plus会员购买订单分页列表报表
export function plusQueryListAPI(params) {
  return request({
    url: '/api/system/vip/plus/order/list',
    method: 'get',
    params
  })
}

// plus会员购买订单分页列表汇总数据报表
export function getTotalVipOrderPayMoney(params) {
  return request({
    url: '/api/system/vip/plus/order/getTotalVipOrderPayMoney',
    method: 'get',
    params
  })
}
