import request from '@/utils/request'

//获取公众号信息  用来判断有没有绑定
export function getTenantWxApp() {
  return request({
    url: '/api/system/tenant/wxApp/getTenantWxApp',
    method: 'get'
  })
}

// 微信公众号授权
export function getAuthorizationImageUrl(wxAppType) {
  return request({
    url: '/api/open/getAuthorizationImageUrl?wxAppType=' + wxAppType,
    method: 'get'
  })
}
// 微信公众号删除
export function wxAppRemove(wxLineIds) {
  return request({
    url: '/api/system/tenant/wxApp/remove',
    method: 'DELETE',
    data: wxLineIds
  })
}
