import request from "@/utils/request";

//获取概览数据
export function getOverviewDataList(data) {
    return request({
      url: '/api/system/vip/report/overview/query/getOverviewData',
      method: 'post',
      data
    })
  }

  