import request from "@/utils/request";

//会员兑换金额查询
export function listExchangeMoneyAPI(params) {
    return request({
      url: '/api/system/vip/report/exchange/query/listExchangeMoney',
      method: 'get',
      params
    })
}
//会员兑换金额查询
export function listExchangeMoneySummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/exchange/query/listExchangeMoneySummary',
    method: 'get',
    params
  })
}

//会员兑换商品查询
export function listExchangeGoodsAPI(params) {
    return request({
      url: '/api/system/vip/report/exchange/query/listExchangeGoods',
      method: 'get',
      params
    })
}
//会员兑换商品查询
export function listExchangeGoodsSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/exchange/query/listExchangeGoodsSummary',
    method: 'get',
    params
  })
}


//会员兑换优惠券查询
export function listExchangeCouponAPI(params) {
    return request({
      url: '/api/system/vip/report/exchange/query/listExchangeCoupon',
      method: 'get',
      params
    })
}
//会员兑换优惠券查询
export function listExchangeCouponSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/exchange/query/listExchangeCouponSummary',
    method: 'get',
    params
  })
}
  
