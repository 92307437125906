import request from '@/utils/request'

// 公众号支付配置详情
export function wxPayConfigDetail () {
  return request({
    url: `/api/system/vip/pay/config/getDetail?payModeId=6`
  })
}

// 公众号支付配置启用/禁用
export function wxPayConfigIsStop () {
    return request({
      url: '/api/system/vip/pay/config/isStop?payModeId=6',
    })
  }

// 公众号支付配置新增/修改
export function wxPayConfigSaveOrUpdate (data) {
    return request({
      url: '/api/system/vip/pay/config/saveOrUpdate',
      method: 'post',
      data
    })
  }