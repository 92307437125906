import request from '@/utils/request'

// 新增会员后台换卡
export function cardChangeSave(data) {
    return request({
        url: '/api/system/vip/bill/cardChange/save',
        method: 'post',
        data
    })
}
// 修改会员后台换卡
export function cardChangeUpdate(data) {
    return request({
        url: '/api/system/vip/bill/cardChange/update',
        method: 'put',
        data: data
    })
}
// 删除会员后台换卡
export function cardChangeDel(billIds) {
    return request({
        url: '/api/system/vip/bill/cardChange/remove',
        method: 'DELETE',
        data:billIds
    })
}

// 审核/反审核/删除
export function cardChangeUpdateStatus(data) {
    return request({
        url: '/api/system/vip/bill/cardChange/updateBillStatus',
        method: 'post',
        data: data
    })
}

// 会员换卡列表
export function cardChangeList(params) {
    return request({
        url: '/api/system/vip/bill/cardChange/list',
        method: 'get',
        params
    })
}
// 会员充值分页列表
export function cardChangeGetDetail(billId) {
    return request({
        url: '/api/system/vip/bill/cardChange/getDetail',
        method: 'get',
        params: {
            billId
        }
    })
}
