import request from "@/utils/request";

// 会员级别列表
export function listlevel(query) {
  return request({
    url: "/api/system/vip/base/level/list",
    method: "get",
    params: query,
  });
}
// 裱花仓库列表
export function listDecoration(query) {
  return request({
    url: "/api/system/goods/store/listDecoration",
    method: "get",
    params: query,
  });
}
// 现烤仓库列表
export function listFreshlyBaked(query) {
  return request({
    url: "/api/system/goods/store/listFreshlyBaked",
    method: "get",
    params: query,
  });
}
// 裱花间列表
export function decorationListRoom(query) {
  return request({
    url: "/api/system/decoration/room/list",
    method: "get",
    params: query,
  });
}
// 现烤仓库列表
export function freshlyBakedListRoom(query) {
  return request({
    url: "/api/system/freshly/baked/room/list",
    method: "get",
    params: query,
  });
}
// 现烤师列表
export function freshlyBakedWorkerList(query) {
  return request({
    url: "/api/system/freshly/baked/worker/list",
    method: "get",
    params: query,
  });
}

//获取会员级别详情
export function getlevelDetail(vipLevelId) {
  return request({
    url: "/api/system/vip/base/level/getDetail",
    method: "get",
    params: { vipLevelId },
  });
}

// 添加会员级别
export function addlevel(data) {
  return request({
    url: "/api/system/vip/base/level/save",
    method: "post",
    data: data,
  });
}

// 修改会员级别
export function updatelevel(data) {
  return request({
    url: "/api/system/vip/base/level/update",
    method: "put",
    data: data,
  });
}

// 删除会员级别
export function dellevel(vipLevelIds) {
  return request({
    url: "/api/system/vip/base/level/remove",
    method: "delete",
    data: vipLevelIds,
  });
}

//会员级别还原接口
export function restoreVipCardLevel(vipLevelIds) {
  return request({
    url: "/api/system/vip/base/level/restoreVipCardLevel",
    method: "post",
    data: vipLevelIds,
  });
}