import request from '@/utils/request'

//会员购物明细查询
export function listShoppingDetailAPI (params) {
  return request({
    url: '/api/system/vip/report/shopping/query/listShoppingDetail',
    method: 'get',
    params
  })
}

//会员购物明细查询(合计汇总)
export function listShoppingDetailSummaryAPI (params) {
  return request({
    url: '/api/system/vip/report/shopping/query/listShoppingDetailSummary',
    method: 'get',
    params
  })
}
