import request from '@/utils/request'

// 新增会员后台状态修改
export function levelChangeSave(data) {
    return request({
        url: '/api/system/vip/bill/levelChange/save',
        method: 'post',
        data
    })
}
// 修改会员后台状态修改
export function levelChangeUpdate(data) {
    return request({
        url: '/api/system/vip/bill/levelChange/update',
        method: 'put',
        data
    })
}

// 删除会员后台状态修改
export function levelChangeDel(billIds) {
    return request({
        url: '/api/system/vip/bill/levelChange/remove',
        method: 'DELETE',
        data:billIds
    })
}
// 修改会员后台级别更换单据状态
export function levelChangeUpdateBillStatus(data) {
    return request({
        url: '/api/system/vip/bill/levelChange/updateBillStatus',
        method: 'post',
        data
    })
}
// 会员后台级别更换分页列表
export function levelChangeList(params) {
    return request({
        url: '/api/system/vip/bill/levelChange/list',
        method: 'get',
        params
    })
}
// 获取会员后台级别更换详细信息
export function levelChangeGetDetail(billId) {
    return request({
        url: `/api/system/vip/bill/levelChange/getDetail?billId=${billId}`,
    })
}
