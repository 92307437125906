import request from '@/utils/request'

// 微信会员投放分析
export function wxVipLaunchList (params) {
    return request({
      url: '/api/system/card/list',
      method: 'get',
      params
    })
  }
