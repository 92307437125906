import request from '@/utils/request'

// 微信服务号模板消息分页列表
export function msgTemplateListAPI (params) {
  return request({
    url: '/api/system/vip/msg/list',
    method: 'get',
    params
  })
}
// 新增微信服务号模板消息
export function msgTemplateAddAPI (data) {
  return request({
    url: '/api/system/vip/msg/save',
    method: 'post',
    data
  })
}
//获取微信服务号模板消息详情
export function msgTemplateDetailAPI (wxTempletMsgId) {
  return request({
    url: '/api/system/vip/msg/getDetail?wxTempletMsgId=' + wxTempletMsgId,
    method: 'get'
  })
}
// 是否启动改模板
export function msgTemplateIsUseAPI (wxTempletMsgId) {
  return request({
    url: '/api/system/vip/msg/isUseWxTemplet?templetMsgId=' + wxTempletMsgId,
    method: 'get'
  })
}
// 导入消息模板
export function msgTemplateImportAPI () {
  return request({
    url: '/api/system/vip/msg/importDefaultTemplate',
    method: 'post'
  })
}
// 删除微信消息模板
export function msgTemplateDeleteAPI (wxTempletMsgIds) {
  return request({
    url: '/api/system/vip/msg/remove',
    method: 'delete',
    data: wxTempletMsgIds
  })
}
// 选择消息模板类型
export function msgSetUpMsgType (data) {
    return request({
        url: '/api/system/vip/msg/setUpMsgType',
        method: 'post',
        data: data
    })
}
// 更新微信服务号模板消息
export function msgUpdate (data) {
    return request({
        url: '/api/system/vip/msg/update',
        method: 'post',
        data: data
    })
}

// 添加模板消息
export function addMessageTemplate (data) {
    return request({
        url: '/api/system/vip/msg/addMessageTemplate',
        method: 'post',
        data: data
    })
}
