import request from '@/utils/request'

// 新增会员后台状态修改
export function stateChangeSave(data) {
    return request({
        url: '/api/system/vip/bill/stateChange/save',
        method: 'post',
        data
    })
}
// 修改会员后台状态修改
export function stateChangeUpdate(data) {
    return request({
        url: '/api/system/vip/bill/stateChange/update',
        method: 'put',
        data
    })
}

// 删除会员后台状态修改
export function stateChangeDel(billIds) {
    return request({
        url: '/api/system/vip/bill/stateChange/remove',
        method: 'DELETE',
        data:billIds
    })
}

// 单据状态修改
export function stateChangeUpdateBillStatus(data) {
    return request({
        url: '/api/system/vip/bill/stateChange/updateBillStatus',
        method: 'post',
        data
    })
}

// 会员后台状态修改分页列表
export function stateChangeList(params) {
    return request({
        url: '/api/system/vip/bill/stateChange/list',
        method: 'get',
        params
    })
}
// 获取会员后台状态修改详细信息
export function stateChangeGetDetail(billId) {
    return request({
        url: '/api/system/vip/bill/stateChange/getDetail',
        method: 'get',
        params:{
            billId
        }
    })
}
