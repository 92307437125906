import request from '@/utils/request'

//会员折扣规则列表
export function vipDiscountRuleList (params) {
    return request({
      url:'/api/system/vip/base/discount/rule/list',
      params
    })
  }
  
  //会员折扣规则修改状态
  export function vipDiscountRuleUpdateStatus (data) {
    return request({
      url:'/api/system/vip/base/discount/rule/updateStatus',
      method:'post',
      data
    })
  }
  
  //会员折扣规则是否启用
  export function vipDiscountRuleIsStop (data) {
    return request({
      url:'/api/system/vip/base/discount/rule/isStop',
      method:'post',
      data
    })
  }
  
  //会员折扣规则复制
  export function vipDiscountRuleCopy (billIds) {
    return request({
      url:'/api/system/vip/base/discount/rule/copy',
      method:'post',
      data: { billIds }
    })
  }