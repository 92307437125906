import request from '@/utils/request'

//会员折扣规则列表
export function vipScoreRuleList (params) {
    return request({
      url:'/api/system/vip/base/score/rule/list',
      params
    })
  }
  
  //会员折扣规则修改状态(审核/反审核)
  export function vipScoreRuleUpdateStatus (data) {
    return request({
      url:'/api/system/vip/base/score/rule/updateStatus',
      method:'post',
      data
    })
  }
  
  //会员折扣规则是否启用
  export function vipScoreRuleIsStop (data) {
    return request({
      url:'/api/system/vip/base/score/rule/isStop',
      method:'post',
      data
    })
  }
  
  //会员折扣规则复制
  export function vipScoreRuleCopy (billIds) {
    return request({
      url:'/api/system/vip/base/score/rule/copy',
      method:'post',
      data: { billIds }
    })
  }