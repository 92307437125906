
import request from "@/utils/request";

//会员发卡记录明细查询
export function getVipBirthday(data) {
    return request({
        url: '/api/system/vip/base/vipcard/getVipBirthday',
        method: 'get',
        params: data
    })
}

//会员发卡记录明细查询
export function getVipBirthdaySummary(data) {
    return request({
        url: '/api/system/vip/base/vipcard/getVipBirthdaySummary',
        method: 'get',
        params: data
    })
}