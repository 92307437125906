import request from "@/utils/request";
// 获取商品详情 
export function goodsGetDetail(query) {
    return request({
        url: "/api/system/goods/info/getDetail",
        method: "get",
        params: query,
    });
}
//商品多单位分页列表
export function muchUnitList(query) {
    return request({
        url: "/api/system/goods/unit/list",
        method: "get",
        params: query,
    });
}


// 获取会员积分兑换商品详情
export function changeGoodsDetail(scoreGoodsId) {
    return request({
        url: "/api/system/vip/base/goods/getDetail?scoreGoodsId=" + scoreGoodsId,
    });
}

// 获会员积分兑换商品分页列表
export function goodsList(query) {
    return request({
        url: "/api/system/vip/base/goods/list",
        method: "get",
        params: query,
    });
}
// 新增会员积分兑换商品
export function addGoodsSave(data) {
    return request({
        url: "/api/system/vip/base/goods/save",
        method: "post",
        data,
    });
}

// 删除会员积分兑换商品
export function goodsRemove (scoreGoodsIds) {
  return request({
    url: '/api/system/vip/base/goods/remove',
    method: 'delete',
    data: scoreGoodsIds
  })
}



// 修改会员积分兑换商品
export function goodsUpdate(data) {
    return request({
        url: "/api/system/vip/base/goods/update",
        method: "put",
        data
    });
}

// 优惠券----------------------------------------------------------------------------

// 获取会员积分兑换优惠券详情
export function couponListDetail(scoreCouponId) {
    return request({
        url: '/api/system/vip/base/coupon/getDetail?scoreCouponId=' + scoreCouponId,
        method: "get",
    });
}
//会员积分兑换优惠券分页列表
export function couponList(query) {
    return request({
        url: "/api/system/vip/base/coupon/list",
        method: "get",
        params: query,
    });
}

// 删除会员积分兑换优惠券
export function couponRemove(scoreCouponId) {
    return request({
        url: "/api/system/vip/base/coupon/remove",
        method: "delete",
        data:scoreCouponId,
    });
}




// 新增会员积分兑换优惠券
export function couponSave(data) {
    return request({
        url: "/api/system/vip/base/coupon/save",
        method: "post",
        data,
    });
}


// 修改会员积分兑换优惠券
export function couponUpdate(data) {
    return request({
        url: "/api/system/vip/base/coupon/update",
        method: "put",
        data,
    });
}
