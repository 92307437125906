
import request from "@/utils/request";

//会员流失查询列表
export function getVipLoss(params) {
    return request({
        url: '/api/system/vip/base/vipcard/getVipLoss',
        method: 'get',
        params
    })
}

//会员流失查询列表(合计汇总)
export function getVipLossSummary(params) {
    return request({
        url: '/api/system/vip/base/vipcard/getVipLossSummary',
        method: 'get',
        params
    })
}

//默认参数
export function getVipReportDefaultCondition(type) {
    return request({
        url: `/api/system/vip/base/vipcard/getVipReportDefaultCondition?type=${type}`,
    })
}