import request from '@/utils/request'

// 微信会员分享记录
export function vipShareList (params) {
  return request({
    url: '/api/system/vip/base/vipcard/vipShareList',
    method: 'get',
    params
  })
}
