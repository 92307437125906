import request from '@/utils/request'

// 会员级别门店列表
export function levelShopList (params) {
  return request({
    url: '/api/system/vip/base/shop/list',
    method: 'GET',
    params
  })
}
// 会员级别门店保存
export function levelSaveOrUpdate (data) {
  return request({
    url: '/api/system/vip/base/shop/saveOrUpdate',
    method: 'POST',
    data
  })
}
