import request from "@/utils/request";

//会员到期明细查询
export function listBecomeDueAPI(params) {
    return request({
      url: '/api/system/vip/report/become/due/query/listBecomeDueDetail',
      method: 'get',
      params
    })
}

//会员到期明细查询(合计汇总)
export function listBecomeDueSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/become/due/query/listBecomeDueDetailSummary',
    method: 'get',
    params
  })
}