import request from "@/utils/request";

//会员优惠券明细查询
export function listCouponDetailAPI(params) {
    return request({
      url: '/api/system/vip/report/coupon/query/listCouponDetail',
      method: 'get',
      params
    })
}
  
//会员优惠券明细查询(合计汇总)
export function listCouponDetailSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/coupon/query/listCouponDetailSummary',
    method: 'get',
    params
  })
}