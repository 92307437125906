import request from '@/utils/request'

//更新会员规则单据状态
export function updateOrderStatus (data, url) {
  return request({
    url,
    method: 'post',
    data
  })
}
//启用停用接口
export function updateEnable (data, url) {
  return request({
    url,
    method: 'post',
    data
  })
}
//复制接口
export function copyOrder (billIds, url) {
  return request({
    url,
    method: 'post',
    data: { billIds }
  })
}
// 会员规则单据分页列表
export function listOrder (query, url) {
  return request({
    url,
    method: 'get',
    params: query
  })
}

//获取会员规则单据详情
export function getOrderDetail (billId, url) {
  return request({
    url: `${url}?billId=${billId}`
  })
}

//新增会员规则单据
export function addOrder (data, url) {
  return request({
    url,
    method: 'POST',
    data
  })
}

//修改会员规则单据
export function updateOrder (data, url) {
  return request({
    url,
    method: 'PUT',
    data
  })
}

//会员充值规则列表
export function vipfillRuleList (params) {
  return request({
    url:'/api/system/vip/base/fill/rule/list',
    params
  })
}

//会员充值规则修改状态
export function vipfillRuleUpdateStatus (data) {
  return request({
    url:'/api/system/vip/base/fill/rule/updateStatus',
    method:'post',
    data
  })
}

//会员充值规则是否启用
export function vipfillRuleIsStop (data) {
  return request({
    url:'/api/system/vip/base/fill/rule/isStop',
    method:'post',
    data
  })
}

//会员充值规则复制
export function vipfillRuleCopy (billIds) {
  return request({
    url:'/api/system/vip/base/fill/rule/copy',
    method:'post',
    data: { billIds }
  })
}