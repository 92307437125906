import request from '@/utils/request'

//会员支付查询明细
export function listPayDetailAPI (params) {
  return request({
    url: '/api/system/vip/report/Pay/query/listPayDetail',
    method: 'get',
    params
  })
}

//会员卡支付（卡汇总）
export function listPayDetailSummary (params) {
  return request({
    url: '/api/system/vip/report/Pay/query/listPayDetailSummary',
    method: 'get',
    params
  })
}
